/* You can add global styles to this file, and also import other style files */
html, body {
    max-height: 100vh;
    height: 100vh;
}
body {
    display: block;
    min-height: calc(100vh + 70px);
    max-height: calc(100vh + 70px);
    height: calc(100vh + 70px);
    margin-top: -70px;
    padding-top: 70px;
}

.btn:focus {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow);
}
.loading {
    opacity: 0;
    transition: opacity .8s ease-in-out;
    position: fixed;
    height: 0%;
    width: 0%;
    z-index: -1;
}
app-root:empty + .loading {
    opacity: 1;
    z-index: 100;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
app-root {
    height: 0px;
    display: block;
}
.loading-container {
    min-width: 100%;
    min-height: 100%;

    background-color: white;
    position: fixed;
    align-content: center;
}
.shimmer {
    color: grey;
    display:inline-block;
    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.8s infinite;
    font-size: 50px;
    max-width:600px;

    margin: auto;
    display: block;
  }
  
  @keyframes shimmer {
    100% {-webkit-mask-position:left}
  }
 
  img {
    display:block;
    margin:0 auto;
    width:600px;
  }


  .sidebar .menu .list .ml-menu li a.menu-revwargrvmark {
    color: #ffae00;
  }
  .sidebar .menu .list .ml-menu li a.menu-revwargrvloc {
    color: #0d6efd;
  }
  .sidebar .menu .list .ml-menu li a.menu-mbrgrvmrkr {
    color: #0d6efd;
  }
